

@font-face {
  font-family: 'Amore';
  src: url('/wp-content/themes/bedu-tours/dist/fonts/Amore.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


$ff-fp-hero: 'Amore', 'Helvetica', 'Arial', sans-serif;
$ff-heading: 'Arvo', 'Helvetica', 'Arial', serif;
//$ff-heading: 'Rubik', sans-serif;
//$ff-heading: 'Nunito Sans', sans-serif;
$ff-copy: 'Lato', 'Helvetica', 'Arial', sans-serif;
$ff-accent: 'Montserrat', 'Helvetica', 'Arial', sans-serif;