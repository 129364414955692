@import "common/variables";
@import "common/mixins";
@import "common/global";
@import "common/animations";
@import "components/require-components";
@import "layouts/require-layouts";
@import "pages/require-pages";

body.woocommerce div#primary {
  float: none!important;
}



//WooCommerce defaults:


.container.rmg-gv-woocommerce {
  margin-top: 60px;
}




div.rmg-gv-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;


  div.rmg-gv-item-outer {

    @media(max-width: 767px) {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }

    @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }

    @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      -ms-flex: 0 0 33.3333333333%;
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }

    @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      -ms-flex: 0 0 33.3333333333%;
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }

  }

}//end rmg-gv-row



