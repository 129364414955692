
body.single-product {

  span.clearfix {
    display: block;
    clear: both;
  }

  /*Hide the main add to cart form*/
  .rmg-gv-cart-form-wrap {
    display: none;
  }


  //PhotoSwipe - make sure it covers all elements when open:
  .pswp {
    z-index: 150000;
  }
  

  div.rmg-gv-gallery-outer {
    float: left;
    width: 48%;
    position: relative;

    @media(max-width: 1024px) and (orientation: portrait) {
      float: none;
      display: block;
      width: 100%!important;
    }
  }


  div.summary.entry-summary {
    @media(max-width: 1024px) and (orientation: portrait) {
      float: none;
      display: block;
      width: 100%!important;
    }
  }


  div.rmg-gv-main-content {

    @media(max-width: 1024px) and (orientation: portrait) {
      width: 100%!important;
    }

    @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      width: 100%!important;
    }

    @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
      width: 100%!important;
    }

  }//endrmg-gv-main-content



  //Add to cart section
  div.rmg-gv-product-summary-add-to-cart {

    div.rmg-gv-voucher-image-options {
      padding-bottom: 15px;
      margin-bottom: 30px;
      border-bottom: solid 1px rgba($pblack, 0.15);
    }

    div.rmg-gv-buy-now-wrap {
      margin-bottom: 0;
    }//end rmg-gv-buy-now

  }//add to cart summary section

  
  div.rmg-gv-buy-now-wrap {

    width: auto;
    text-align: left;
    padding-right: 5px;

    @media(max-width: 767px) {
      padding-right: 0;
    }

    @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      margin: 0;
      text-align: right;
    }

    input[type="number"], button.rmg-gv-buy-now {
      display: inline-block;
      vertical-align: middle;
    }

    input[type="number"] {
      width: 75px;
      margin-right: 18px;
      @include border-radius(0);
      border: solid 1px #000;
      padding: 3px 0;
      outline: none!important;
    }

    button.rmg-gv-buy-now {
      display: inline-block;
      vertical-align: middle;
      width: auto;
      margin: 0;
      @include transition(background .3s ease-in-out);

      @media(max-width: 767px) {
        display: block;
        margin: 15px auto 0;
      }
    }

  }//end div.rmg-gv-buy-now


  div.rmg-gv-buy-as-gift-wrap {
    text-align: right;
    position: relative;
    width: auto;
    padding-left: 8px;
  }//end rmg-gv-buy-as-gift-wrap
  

  //Voucher tpl image options
  div.rmg-gv-voucher-image-options {

    div.rmg-gv-voucher-image-option {
      display: inline-block;
      vertical-align: top;
      padding: 0 8px;
      text-align: center;

      &.rmg-gv-tpl-cols-1 {
        width: 50%;
      }

      &.rmg-gv-tpl-cols-2 {
        width: 50%;
      }

      &.rmg-gv-tpl-cols-3 {
        width: 33.3333%;
      }

      &.rmg-gv-tpl-cols-4 {
        width: 50%;
        margin: 10px 0;
      }


      a {
        display: block;
        position: relative;

        &:hover {
          span.rmg-gv-icon-wrap {
            @include opacity(100, 1);
          }
        }

        span.rmg-gv-icon-wrap {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          text-align: center;
          @include transition(all .3s ease-in-out);
          background-color: rgba(#fff, 0.5);
          @include opacity(0, 0);

          background-image: url(/wp-content/plugins/rmg-gift-vouchers/public/dist/images/icons/preview.png);
          background-position: center center;
          background-repeat: no-repeat;
          -webkit-background-size: 30px 30px;
          background-size: 30px 30px;

          
        }
      }

      input[type="radio"] {
        display: none;
      }

      img.rmg-gv-voucher-tn {
        width: 100%;
        height: auto;
        object-fit: contain;
        object-position: center;
        border: solid 1px #000;
      }

      &:not(.rmg-gv-tpl-selected) {
        img.rmg-gv-voucher-tn {
          @include opacity(35, 0.35);
          border: solid 1px transparent;
        }
      }//not selected


      button.rmg-gv-tpl-select {
        margin-top: 15px;
        @include prefix(border-radius, 0);
        font-size: $fs-sm;
        padding: 2px 10px;
        border: none;
        color: $white;
      }


      &.rmg-gv-tpl-selected {
        button.rmg-gv-tpl-select {
          @include opacity(35, 0.35);
          cursor: not-allowed;
        }
      }//end rmg-gv-tpl-selected


    }//end rmg-gv-voucher-image-option


  }//end rmg-gv-voucher-image-options


  .rmg-gv-product-summary-add-to-cart {
    @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      text-align: center;
    }
  }


  //Button styles:
  div.rmg-gv-buy-now-wrap, div.rmg-gv-buy-as-gift-wrap {
    display: inline-block;
    vertical-align: top;

    @media(max-width: 767px) {
      display: block;
      width: 100%!important;
      text-align: center!important;
    }

    @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      display: block;
      width: 100%!important;
      text-align: center!important;
    }

    @media(min-width: 834px) and (max-width: 1191px) and (orientation: landscape) {
      display: block;
      width: 100%!important;
      text-align: center!important;
    }
  }

  div.rmg-gv-buy-as-gift-wrap {
    border-left: solid 1px $off-white;
    position: relative;

    @media(max-width: 767px) {
      border-left: none;
      padding-left: 0;
      margin: 0 auto;
    }

    @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      text-align: left;
      width: auto;
    }

    @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      border-left: none;
      padding-left: 0;
    }
  }

  button.rmg-gv-buy-now, button#rmg-gv-buy-as-gift {
    position: relative;
    display: inline-block;
    width: auto;
    padding: 6px 10px 9px;
    font-size: 12px;
    font-family: Lato,Helvetica,Arial,sans-serif;
    line-height: 20px;
    padding: 6px 10px 4px;
    @include prefix(border-radius, 2px);

    @media(max-width: 767px) {
      padding: 8px 10px 6px;
    }

    span.rmg-gv-icon-wrap {
      img {
        width: 15px;
        height: auto;
        margin-right: 5px;
      }
    }

  }//end buy now / buy as gift





  p.rmg-gv-voucher-style-heading {
    margin-bottom: 5px;

    &.rmg-gv-images-hidden {
      display: none;

      & + div.rmg-gv-voucher-image-options {
        display: none;
      }
    }
  }



  //The customisation section:
  div.rmg-gv-voucher-customisation {

    max-height: 0;
    overflow: hidden;
    clear: both;
    @include transition(all .3s ease-in-out);
    @include opacity(0, 0);

    h2 {
      padding: 15px 15px 0;

      @media(max-width: 767px) {
        margin-bottom: 0;
      }

      @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        text-align: center;
        margin-bottom: 0;
      }
    }


    &.active {
      max-height: 1500px;
      @include opacity(100, 1);
      margin-top: 30px;
    }


    div.inner {

      @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        display: flex;
        flex-direction: column;
      }

    }//end inner


    div.inner > div {
      width: 50%;
      display: inline-block;
      vertical-align: top;
      position: relative;

      @media(max-width: 767px) {
        display: block;
        width: 100%;
      }


      @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        flex: 1;
        width: 100%;
      }

    }


    .rmg-gv-voucher-settings {
      padding-right: 15px;

      @media(max-width: 767px) {
        padding-right: 0;
      }

      @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        order: 2;
        padding-right: 0;
        margin-top: 30px;
      }

      @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        width: 40%!important;
      }



      @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
      	width: 40%!important;
      }

      label.rmg-gv-field-label {
        font-size: 14px;
        margin-bottom: 0;

        & + p.form-row {
          margin: 0;
          padding: 0;
          width: 100%;

          & + label.rmg-gv-field-label {
            margin-top: 15px;
          }

          span, input, textarea {
            width: 100%;
          }
          
          
          input, textarea {
            background: $white;
          }

          textarea {
            height: 120px;
          }
        }
      }

    }//end .rmg-gv-voucher-settings



    .rmg-gv-voucher-preview {
      padding: 8px 15px;

      @media(max-width: 767px) {
        display: none!important;
      }

      @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        order: 1;
      }

      @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        width: 60%!important;
      }

      @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
        width: 60%!important;
      }
    }


    .rmg-gv-add-to-cart {
      @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        order: 3;
      }
    }


    img.rmg-gv-voucher-preview-img {
      @include box-shadow-hover;
    }



    div.rmg-gv-inputs-wrap {

      position: absolute;
      top: 45%;
      left: 0;
      width: 100%;
      height: auto;
      padding: 0 8.5%;

      @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
        top: 50%;
      }

      p.rmg-gv-recipient {
        font-size: 14px;
        font-weight: 400;
        padding-top: 10px;
        margin-bottom: 5px;
        color: #000;
        background-color: transparent;
        border: none;
        text-align: center;
      }

      p.rmg-gv-message {
        margin-bottom: 10px;
        margin-top: 0;
        height: 75px;
        color: #000;
        background-color: transparent;
        border: none;
        text-align: center;
        padding: 0;
        resize: none;
        width: 100%;
        line-height: 1.2;
        font-size: 16px;
      }


    }//end rmg-gv-inputs-wrap





  }//end rmg-gv-voucher-customisation



  section.rmg-gv-related-products {
    margin-top: 0!important;
    border-top: none!important;
    padding-top: 0!important;

    div.rmg-gv-item-outer {
      width: 100%;
      padding: 0;
      flex: 1;
      max-width: none;
    }

    & > h3 {
      border-bottom: solid 1px #bfa64c;
      padding-bottom: 15px;
      margin-bottom: 30px;
      text-align: center;
      font-size: $h3-fs;
      font-weight: 700;
    }

  }//end rmg-gv-related-products


  div.rmg-gv-short-desc + .rmg-gv-product-summary-add-to-cart {
    margin-top: 30px;
  }


  div.rmg-gv-product-summary-wrap {
    padding-bottom: 60px;
  }

}//end single product





//Languages
html[lang="es-ES"] body.single-product, html[lang="fr"] body.single-product {

  @media(min-width: 1025px) {
    div.rmg-gv-buy-now-wrap {
      width: 100%;
      text-align: center;
      margin: 0;
    }

    div.rmg-gv-buy-as-gift-wrap {
      width: 100%;
      text-align: center;
      margin-top: 15px;
      margin-left: 0;
      padding-left: 0;
    }
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-height: 1366px) and (min-width: 1024px) and (orientation: portrait) {
    div.rmg-gv-buy-now-wrap {
      width: 30%;
    }
  }

}//end html[lang="es-ES"] body.single-product



//Theme compat - extend plugin styles with Autonomy theme styles:
body.single-product {


  .rmg-gv-product-title {
    text-align: center;
    margin-bottom: 30px;
  }//end rmg-gv-product-title


  div.rmg-gv-gallery-outer {
    width: 60%;
    padding-right: 15px;

    @media(max-width: 1024px) and (orientation: portrait) {
      padding-right: 0;
    }

    .rmg-gv-gallery-img img {
      height: 500px;
      object-fit: cover;
      object-position: center;

      @media(max-width: 767px) {
        height: 240px;
      }

      @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        height: 350px;
      }

      @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        height: 400px;
      }
    }
  }//end div.rmg-gv-gallery


  div.rmg-gv-gallery-outer .rmg-gv-short-desc {
    p {
      font-size: $h5-fs;
      margin-top: 30px;
    }
  }


  .summary.entry-summary {
    width: 40%!important;
    padding-left: 15px;
    margin-top: 0;
    margin-bottom: 0;


    @media(max-width: 1024px) and (orientation: portrait) {
      padding-left: 0!important;
    }


    h2 {
      font-size: $h3-fs;
      margin: 0 0 15px 0;
    }

  }//end entry-summary


  div.rmg-gv-variations {

    margin-bottom: 30px;

    & > p {
      margin-bottom: 5px;
      font-weight: bold;
    }


    label {
      background: $off-white;
      font-weight: normal;
      display: block;
      padding: 15px 15px 15px 45px;
      position: relative;

      @media(max-width: 767px) {
        font-size: 14px;
      }

      input {
        position: absolute;
        top: 16px;
        left: 15px;
      }

      span.price {
        font-size: 16px;
        display: block;
        color: #bfa64c;
      }
    }

  }//end .rmg-gv-variations


  div.rmg-gv-product-summary-add-to-cart  {

    p {
      font-weight: bold;
    }

    div.rmg-gv-voucher-image-options {
      background: $off-white;
      padding: 15px 5px;
    }

    .rmg-gv-buy-as-gift-wrap {

      margin-top: 0;

      @media(max-width: 767px) {
        margin-top: 15px;
      }

      @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        margin-top: 15px;
      }

      @media(min-width: 834px) and (max-width: 1191px) and (orientation: landscape) {
        margin-top: 15px;
      }

      button#rmg-gv-buy-as-gift {
        text-transform: uppercase;
        background: #bfa64c;
        color: $white;
        @include transition(background .3s ease-in-out);

        &:hover {
          background: #bfa64c;
        }
      }

    }//end .rmg-gv-buy-as-gift-wrap

    span.rmg-gv-icon-wrap img {
      width: 17px;
      height: auto;
      margin-right: 5px;
      position: relative;
      top: -1px;
    }

  }//end rmg-gv-product-summary-add-to-cart


  div.rmg-gv-main-content {

    width: 65%;
    padding-right: 15px;
    padding-top: 15px;
    margin-top: 30px;

    @media(max-width: 991px) {
      padding-right: 0;
    }

    @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      padding-right: 0;
    }

    div.rmg-gv-content-block + div.rmg-gv-content-block {
      margin-top: 15px;
      padding-top: 15px;
      border-top: solid 1px #eee;
    }

    .rmg-gv-content-block h3,
    .rmg-gv-content-block h3 + ul,
    .rmg-gv-content-block h3 + p {
      display: inline-block;
      vertical-align: middle;

      @media(max-width: 767px) {
        display: block;
        width: 100%!important;
        text-align: center!important;
      }
    }


    .rmg-gv-content-block h3 + ul {
      @media(max-width: 767px) {
        text-align: left!important;
      }
    }


    .rmg-gv-content-block.rmg-gv-the-content {

      margin-top: 15px!important;
      padding-top: 30px!important;

      & * {
        display: block;
      }

      h3 {
        margin-bottom: 15px!important;
      }
    }

    .rmg-gv-content-block h3 {
      width: 35%;

      @media(max-width: 767px) {
        margin-bottom: 15px;
      }

      & + * {
        width: 65%;
        margin-left: -5px!important;
        padding-left: 15px!important;
        margin-bottom: 0!important;
      }
    }//end h3

    @media(max-width: 767px) {
      padding-right: 0;
    }

    @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      padding-right: 0;
    }

    h3 {
      padding: 0;
      margin: 0;
      font-size: 18px;
      font-weight: 700;
    }

    ul.rmg-gv-included-list {
      margin: 0;
      padding: 0 0 0 32px!important;

      @media(max-width: 767px) {
        text-align: left!important;
      }

      li + li {
        margin-top: 8px;
      }
    }

  }//end .rmg-gv-main-content


  .rmg-gv-buy-now-wrap {

    margin-bottom: 8px;
    text-align: center;

    label[for="rmg-gv-cart-qty"] {
      font-weight: normal;
      font-size: 14px;
      color: #bfa64c;
      margin: 0 5px 0 0;
      vertical-align: middle;
    }

    input[type=number] {
      text-align: center;
      line-height: 1;
      display: inline-block;
    }

    button.rmg-gv-buy-now {
      text-transform: uppercase;
      background: #bfa64c;
      color: $white;

      &:hover {
        background: #bfa64c;
      }
    }

  }//end .rmg-gv-buy-now-wrap


  div#customise-voucher {

    background: $off-white;
    border-bottom: solid 1px transparent;
    @include transition(all .3 ease-in-out);

    &.active {
      border-bottom: 1px solid rgba($black,.15);
    }

    div.inner {
      padding: 15px;
      margin-bottom: 15px;
    }

    h2 {
      margin-top: 0;
      font-size: $h3-fs;
    }

    .rmg-gv-add-to-cart {
      width: 100%!important;
      text-align: center;
      padding: 15px 0 0 0;
      margin-top: 15px;
    }

    .rmg-gv-buy-now-wrap {
      margin-bottom: 0;
      display: block;
      width: 100%;
      text-align: center;
    }

    button.rmg-gv-buy-now {
      max-width: 300px;
    }

    .rmg-gv-char-count {
      text-align: right;
      font-size: 12px;
      margin: 0;
      margin-top: 0;
      color: rgba($black, 0.5);
    }

    p.rmg-gv-voucher-style-heading {
      font-weight: bold;
      margin-top: 15px;
    }

  }//end div#customise-voucher


}//end single-product


















