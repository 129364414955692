
$white: #fff;
$off-white: #F8F8F8;

$pblack: #000;
$black: #383838;
$grey: #D2D2D2;

$med-grey: #b0b0b0;

$red: #F46A4E;
$red-hover: darken($red, 8);


$orange: #F4874B;
$orange-hover: darken($orange, 8);


$yellow: #F3B05A;

$purple: #5C4A72;
$purple-hover: darken($purple, 8);

$maroon: #A3586D;
$maroon-hover: darken($maroon, 8);



