div.rmg-gv-shop-carousel {

  padding: 0 15px;

  //Owl nav
  .owl-nav > button {
    background: transparent !important;
    font-size: 120px !important;
    position: absolute;
    top: 50%;
    width: 50px;
    height: auto;
    margin: 0 !important;
    @include prefix(transform, translateY(-50%));
    cursor: pointer;
    @include transition(all .3s ease-in-out);
    color: #404040;
    @include prefix(box-shadow, none);

    @media (max-width: 1024px) {
      font-size: 90px !important;
      width: auto;
    }

    &:hover {

      color: #404040!important;

      &.owl-prev {
        left: -60px;

        @media (max-width: 1024px) {
          left: -30px;
        }
      }

      &.owl-next {
        right: -60px;

        @media (max-width: 1024px) {
          right: -30px;
        }
      }
    }

    span {
      top: -12px;
      display: block;
      position: relative;
    }

    &.owl-prev {
      left: -55px;

      @media (max-width: 1024px) {
        left: -30px;
      }
    }

    &.owl-next {
      right: -55px;

      @media (max-width: 1024px) {
        right: -30px;
      }
    }
  }

}

//end rmg-gv-shop-carousel


div.product.rmg-gv-item-outer {
  margin-bottom: 30px!important;
}


div.rmg-gv-product-item {

  text-align: center;
  background: #fff;
  border: solid 1px rgba(#000, 0.15);
  position: relative;
  height: 100%;


  h3 {
    font-size: 16px;
    text-transform: uppercase;
    margin: 0;
    padding: 15px 10px;
    line-height: 1.4;
  }


  div.content-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background: $white;
  }


  .rmg-gv-product-desc-wrap {
    max-height: 0;
    overflow: hidden;
    @include transition(all .65s ease-in-out);
    position: absolute;
    background: #fff;
    width: 100%;
    z-index: 1;

    p {
      border-top: 1px solid #bfa64c;
      padding: 10px 15px;
      margin: 0;
    }
  }


  div.rmg-gv-img-wrap {

    a {
      display: block;
      position: relative;
    }

    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      object-position: center;
    }
  }


  div.price-wrap {
    padding: 15px;
  }


  div.rmg-gv-product-btn-wrap {
    margin-top: 15px;
  }


  div.rmg-gv-product-price-wrap {
    color: #bfa64c;
    span {
      color: #bfa64c;
    }
  }


  a.rmg-gv-btn {
    color: $white;
    background-color: #bfa64c;
    text-transform: uppercase;
    font-size: $fs-sm;
    @include transition(background-color .3s ease-in-out);
    text-decoration: none;
    display: inline-block;
    padding: 6px 10px 4px;

    &:hover {
      background-color: #8c7932;
    }
  }


  &:hover {
    div.rmg-gv-product-desc-wrap {
      max-height: 450px;
    }
  }


}


div.rmg-gv-shop-carousel {

  div.rmg-gv-item-outer {
    margin-bottom: 0!important;
  }

}//end rmg-gv-shop-carousel










































