//Shop slider styles
div.rmg-gv-hero-carousel {

  position: relative;
  width: 100%;

  .rmg-gv-carousel-item {
    height: 65vh;

    @media(max-width: 767px) {
      height: 45vh;
    }

    @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      height: 45vh;
    }

    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
//end .rmg-gv-hero-carousel


div.rmg-gv-before-shop-loop-wrap {
  position: relative;
  display: block;
  clear: both;


  div.woocommerce-notices-wrapper {
    display: block;
  }


  p.woocommerce-result-count, form.woocommerce-ordering {
    float: none;
    display: inline-block;
    vertical-align: top;
    width: 50%;

    @media(max-width: 767px) {
      display: block;
      width: 100%;
      text-align: center;
    }
  }

  form.woocommerce-ordering {
    margin-left: -6px;
    text-align: right;

    @media(max-width: 767px) {
      margin-left: 0;
      text-align: center;
      margin-top: 10px;
    }
  }

}//end rmg-gv-before-shop-loop-wrap


div.rmg-gv-shop-page {

  padding-bottom: 60px;

  header.woocommerce-products-header {
    text-align: center;

    h1 {
      font-size: $h2-fs;
      margin-bottom: 30px;

      @media(max-width: 991px) {
        font-size: $h3-fs;
      }
    }
  }


  //Categories
  ul.rmg-gv-categories {
    padding: 15px 0;
    margin: 0;
    list-style: none;
    text-align: center;

    li {
      display: inline-block;
      vertical-align: top;

      @media(max-width: 991px) {
        padding: 0 10px;
      }

      & + li {
        margin-left: 30px;

        @media(max-width: 991px) {
          margin-left: 0;
        }
      }

      a {
        text-transform: uppercase;
        font-size: 16px;
      }
    }
  }



  //Categories img section
  .rmg-gv-after-shop-loop-wrap {
    position: relative;

    h2 {
      margin-top: 45px;
      margin-bottom: 30px;
      text-align: center;
      font-size: $h3-fs;
    }

    ul.rmg-gv-categories {
      margin-top: 0;
      padding: 0;

      li {
        width: calc(25% - 12px);
        display: inline-block;
        vertical-align: top;
        background: #fff;
        padding: 0!important;

        &:hover {
          .rmg-gv-cat-img-wrap a:before {
            background: rgba(#000, 0.15);
          }
        }

        @media(max-width: 991px) {
          width: calc(50% - 30px);
          margin: 0 15px 30px;
        }

        @media(max-width: 767px) {
          display: block;
          width: 100%;
          margin: 0;
        }



        & + li {
          margin-left: 15px;

          @media(max-width: 767px) {
            margin-left: 0;
            margin-top: 30px;
          }
        }

        .rmg-gv-cat-img-wrap {

          position: relative;

          a {
            display: block;
            position: relative;
            text-decoration: none!important;
            color: #99821b;



            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(#000, 0.0001);
              @include transition(all .3s ease-in-out);
            }
          }

          img {
            width: 100%;
            height: 240px;
            object-fit: cover;
            object-position: center;

            @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
              height: 140px;
            }
          }

          & + a {
            display: block;
            padding: 5px;
            &:visited, &:active {
              color: #99821b;
            }
          }
        }
      }

    }//end rmg-gv-categories


    ul.hiw {
      margin: 0;
      padding: 0;
      position: relative;

      li {
        display: inline-block;
        vertical-align: middle;
        width: 50%;
        padding: 0 15px;
        text-align: center;
        margin-bottom: 30px;

        @media(max-width: 767px) {
          width: 100%;
          display: block;

          & + li {
            margin-top: 30px;
          }
        }


        @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
          vertical-align: top;
        }

        strong {
          text-transform: uppercase;
          font-size: 16px;
          margin-bottom: 15px;
        }

        strong, span {
          display: block;
        }

        span {
          width: 100%;
          max-width: 465px;
          margin: 0 auto;
        }

        img {
          width: 100%;
          max-width: 65px;
          height: auto;
          margin: 15px 0;
        }
      }
    }


  }//end rmg-gv-after-shop-loop-wrap


}//end rmg-gv-shop-page




/*Theme compat - extend default plugin styles to work with Autonomy theme*/
nav.woocommerce-breadcrumb, p.woocommerce-result-count, form.woocommerce-ordering {
  display: none!important;
}


body.post-type-archive-product {

  ul.rmg-gv-categories:not(.rmg-gv-cats-imgs) {

    margin-top: 15px;

    @media(max-width: 991px) {
      margin-top: 0;
    }

    li {
      @media(max-width: 991px) {
        width: 50%;
      }
    }

    li a {

      background-color: #bfa64c;
      display: inline-block;
      padding: 6px 10px 4px;
      font-size: $fs-sm;
      text-decoration: none !important;
      @include transition(background .3s ease-in-out);
      min-width: 165px;

      @media(max-width: 991px) {
        margin-top: 8px;
        min-width: 0;
        width: 100%;
      }

      &:hover {
        background: #8c7932;
      }


      &, &:active, &:visited {
        color: #fff;
      }
    }
  }


  .container.rmg-gv-woocommerce.rmg-gv-shop-page {
    @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      width: 100%;
    }
  }


  //Set the width of the shop image slider:
  div.rmg-gv-hero-carousel {
    width: 100%;
  }

}//end archive product



body.tax-product_cat.archive {

  //Back to shop link
  a.back-to-shop {
    display: inline-block;
    color: #000;
    font-size: $fs-sm;
    text-decoration: underline;
    text-decoration-color: transparent;
    @include transition(all .3s ease-in-out);

    &:hover, &:active, &:focus {
      text-decoration-color: #000;
    }

    span.icon-wrap {
      margin-right: 5px;
      font-size: $fs-xs;
    }
  }

}





































