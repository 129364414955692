
//Cart link
li a.rmg-gv-cart-link {

  img {
    width: 21px;
    height: auto;
  }

}//end a.rmg-gv-cart-link
