$base-fs: 16px;
body{
  font-size:$base-fs;
  color: $black;
}


$h1-fs: 3.998em;
$h2-fs: 2.827em;
$h3-fs: 1.999em;
$h4-fs: 1.414em;
$h5-fs: 18px;
$p-fs:  1em;
$fs-sm: 14px;
$fs-xs: 12px;
$p-lh: 26px;

h1{
  font-size: $h1-fs;
  font-weight: bold;
  font-family: $ff-heading;

  @media(max-width: 767px) {
    font-size: $h2-fs;
  }
}

h2{
  font-size: $h2-fs;
  font-weight: bold;
  font-family: $ff-heading;
  margin-bottom: 30px;

  @media(max-width: 767px) {
    font-size: $h3-fs;
  }
}

h3{
  font-size: $h3-fs;
  font-family: $ff-copy;
  margin-bottom: 15px;

  @media(max-width: 767px) {
    font-size: $h4-fs;
  }

  @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: $h4-fs;
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  	font-size: $h3-fs;
  }
}

h4{
  font-size: $h4-fs;
  font-family: $ff-copy;
  margin-bottom: 15px;
}

p, ul li, label, input, textarea, strong, button{
  font-size: $base-fs;
  font-family: $ff-copy;
}

p {
  margin-bottom: 0;

  & + p {
    margin-top: 15px;
  }
}








/*TPL Typo*/
//
//html * {
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//}
//
//body {
//  font-size: $font-size-base;
//  line-height: $line-height-base;
//}
//
//p {
//  margin-bottom: 30px;
//}
//
//strong {
//  font-weight: 600;
//}
//
//label {
//  font-weight: 500;
//  margin-bottom: 3px;
//}
//
//hr {
//  margin: 30px 0 30px 0;
//  border-color: $border-color-2;
//  &.large {
//    border-color: $border-color-2;
//    border-top-width: 4px;
//    margin-top: 60px;
//    @include tablet {
//      margin-top: 45px;
//    }
//    @include mobile {
//      margin-bottom: 45px;
//    }
//  }
//}
//
//ul, ol {
//  list-style: none;
//  margin: 0 0 25px 0;
//  padding: 0;
//}
//
//.main_title {
//  text-align: center;
//  margin-bottom: 45px;
//  position: relative;
//}
//
//.main_title span {
//  content: "";
//  display: block;
//  width: 60px;
//  height: 3px;
//  margin: auto;
//  margin-bottom: 20px;
//  opacity: 1;
//  background-color: #999;
//  visibility: hidden;
//  -webkit-transform: scaleX(0);
//  transform: scaleX(0);
//  -webkit-transition: all 0.6s;
//  transition: all 0.6s;
//}
//
//.main_title.animated span {
//  visibility: visible;
//  -webkit-transform: scaleX(1);
//  transform: scaleX(1);
//}
//
//.main_title_2 {
//  span {
//    width: 120px;
//    height: 2px;
//    background-color: #e1e1e1;
//    display: block;
//    margin: auto;
//    em {
//      width: 60px;
//      height: 2px;
//      background-color: $color-1;
//      display: block;
//      margin: auto;
//    }
//  }
//  text-align: center;
//  margin-bottom: 45px;
//  position: relative;
//  @include mobile {
//    margin-bottom: 10px;
//  }
//  h2 {
//    margin: 25px 0 0 0;
//    @include mobile {
//      @include fontSize(26px);
//    }
//  }
//  h3 {
//    margin: 25px 0 0 0;
//    @include mobile {
//      @include fontSize(24px);
//    }
//  }
//  p {
//    @include fontSize(24px);
//    font-weight: 300;
//    line-height: 1.4;
//    @include mobile {
//      @include fontSize(18px);
//    }
//  }
//}
//
//.main_title_3 {
//  span {
//    width: 120px;
//    height: 2px;
//    background-color: #e1e1e1;
//    display: block;
//    em {
//      width: 60px;
//      height: 2px;
//      background-color: $color-1;
//      display: block;
//    }
//  }
//  margin-bottom: 25px;
//  position: relative;
//  @include mobile {
//    margin-bottom: 10px;
//  }
//  h2, h3 {
//    @include fontSize(23px);
//    margin: 25px 0 0 0;
//    @include mobile {
//      @include fontSize(21px);
//    }
//  }
//  p {
//    @include fontSize(18px);
//    font-weight: 300;
//    line-height: 1.4;
//  }
//}
/*TPL Typo*/





































